import React from "react";
import { Link } from "gatsby";
import AOS from "aos";

import { StaticImage } from "gatsby-plugin-image"

const Hero = ({ className, ...rest }) => {
  setTimeout(() => {
    AOS.refresh();
  }, 500);

  return (
    <div className={className} {...rest}>
      <div className="container position-static">
        <div className="row position-static">
          <div className="col-xl-8 col-lg-8 col-md-10 col-sm-12">
            <div
              className="mt-8 mt-lg-0 pt-lg-16 mr-xl-25 mr-lg-10"
              data-aos="fade-right"
              data-aos-duration={600}
              data-aos-once="true"
            >
              <h1 className="font-size-10 mb-9 line-height-84 pr-xs-15 pr-lg-0">
                industry-standard{" "}
                <span style={{ color: "rgb(233, 83, 64)" }}>forms</span>
                <br /> <span style={{ color: "rgb(233, 83, 64)" }}>
                  vetted
                </span>{" "}
                by professionals
                <br /> beautiful{" "}
                <span style={{ color: "rgb(233, 83, 64)" }}>layouts</span>
                <br />a{" "}
                <span style={{ color: "rgb(233, 83, 64)" }}>
                  lightning fast
                </span>{" "}
                app
                <br />
                <br />
                <span style={{ color: "rgb(233, 83, 64)" }}>
                  all at your fingertips.
                </span>
              </h1>
              <p>
                Thousands of companies of all sizes use FormPigeon's prebuilt
                form libraries to service their customers, brand their
                businesses, integrate with other services, and more.
              </p>
              <br />
              <a href="https://app.formpigeon.com/#/register">
                <button className="btn btn-blue-3 btn-sm rounded-5">
                  Start now &nbsp;{">"}
                </button>
              </a>
              <Link
                to="/contact"
                style={{ whiteSpace: "pre-wrap" }}
                className="btn btn-2 btn-sm rounded-5"
              >
                Contact Sales {">"}
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-10 position-static pt-14 pt-lg-0">
          <div className="custom-hero-image-group pt-lg-3">
            <div className="hero-images">
              <div className="img-1">
                <StaticImage
                  className="w-100 w-lg-auto shadow-14 opacity-8 rounded-top-8"
                  src="../../assets/image/home-4/png/l4-hero-desktops-img.png"
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration={600}
                  data-aos-once="true"
                />
              </div>
              <div className="img-2">
                <StaticImage
                  className="w-100 w-lg-auto shadow-14 rounded-top-15"
                  src="../../assets/image/home-4/png/l4-hero-phone-img.png"
                  alt=""
                  data-aos="fade-left"
                  data-aos-duration={500}
                  data-aos-once="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
